
require("jquery")

document.addEventListener('DOMContentLoaded', () => {
    let recipeFilter = document.querySelector('#recipe-filter')
    if (recipeFilter){
        recipeFilter.addEventListener('change', function(){
            let selectedItem = recipeFilter.options[recipeFilter.selectedIndex]
            $.ajax({   
                url: selectedItem.getAttribute('data-url'),
                type: 'GET',
                success: function(result){
                    eval(result)
                }, 
                error: function(error){
                    console.log(error)
                } 
            });
        })
        
    }

})
