
export default function(){
	let body = document.querySelector('body')
 // importante ordine delle azioni! attiva la classe noscroll prima e dopo correttamente
	if(body.classList.contains('noscroll')){
		body.classList.remove('noscroll')
		document.documentElement.scrollTop = -(parseInt(body.style.top))
	} else {
		body.style.top = -(document.documentElement.scrollTop) + 'px'
		body.classList.add('noscroll')
	}

	
	// menu.classList.toggle('is-active')
	// hamburger.classList.toggle('is-active')
	// if(!menu.classList.contains('is-active')){
	// 	body.style.top = -(document.documentElement.scrollTop) + 'px'	
	// } else {
	// 	let scrollTo = body.style.top
	// 	window.scrollTo(0, -scrollTo)
	// }
}



