// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")
require("jquery")
require('components/accordions')
require('components/commons')
require('components/fade-in-scroll')
require('components/header')
require('components/job_items')
require('components/lazyload')
require('components/load_more_news')
require('components/map')
require('components/menu')
require('components/recipes')
require('components/scroll-to')
require('components/story')
require('components/sliders')
require('components/video')



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import fadeInScroll from "../components/fade-in-scroll.js"

/// PAGE FADEIN ANIMATION
let fadeInTime;
window.location.pathname == '/' ? fadeInTime = 3 : fadeInTime = 0.5
document.addEventListener('DOMContentLoaded', ()=> {

  if (window.innerWidth < 768){
    fadeInTime = fadeInTime / 3
  }
	gsap.to(['body','html'], fadeInTime, {opacity: 1, ease: Power4.easeInOut} )
	fadeInScroll();

	watchForHover()

})

function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return
    document.body.classList.add('hasHover')
  }

  function disableHover() {
    document.body.classList.remove('hasHover')
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date()
  }

  document.addEventListener('touchstart', updateLastTouchTime, true)
  document.addEventListener('touchstart', disableHover, true)
  document.addEventListener('mousemove', enableHover, true)

  enableHover()
}
