import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]

import noscroll from'./noscroll'


const toggleMenu = () => {
	let menu = document.querySelector('.js-main-menu')
	let hamburger = document.querySelector('.js-toggle-main-menu')
	let body = document.querySelector('body, html')
	var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
	if (isSafari) { 
	  menu.classList.add('is-safari')
	};
	if(!menu.classList.contains('is-active')){
		gsap.fromTo( menu.querySelectorAll('.cta'), {autoAlpha:0, x:-10}, {duration: 0.3, autoAlpha:1, x:0, stagger:0.08, ease: Power4.out})
		// document.addEventListener('click', closeMenuIfOutside)
	} else {
		gsap.to( menu.querySelectorAll('.cta'), {autoAlpha:0, x:-10, duration: 0.3})
		// document.removeEventListener('click', closeMenuIfOutside)
	}
	menu.classList.toggle('is-active')
	hamburger.classList.toggle('is-active')
	noscroll()
	// pageContents.classList.toggle('make-blur')
	setTimeout(function() {
		if(menu.classList.contains('is-active')){
			document.addEventListener('click', closeMenuIfOutside)
		}
	}, 1000)

}

const closeMenuIfOutside = (e) => {
	let menu = document.querySelector('.js-main-menu')
	let menuInner = document.querySelector('.main-menu_inner')
	let hamburger = document.querySelector('.js-toggle-main-menu')
	let mobileSearch = document.querySelector('.header_search.mobile_search')
	let body = document.querySelector('body')
	let html = document.querySelector('html')
	let header = document.querySelector('.header')
	if(menu.classList.contains('is-active')){
		let item = e.target
		let parents = []
		for(let n in item){
			item = item.parentNode;
			if(item.nodeName == 'BODY'){
				break;
			}
			parents.push(item)
		}
		let isHeader = false
		for (var i = parents.length - 1; i >= 0; i--) {
			if(parents[i].classList.contains('header')){
				isHeader = true
				break;
			}
		}
		if(e.target.parentNode != menuInner && 
		   e.target != menuInner && 
		   e.target.parentNode != mobileSearch
		   && isHeader != true){
			gsap.fromTo( menu.querySelectorAll('.cta'), {autoAlpha:0, x:-10}, {duration: 0.3, autoAlpha:1, x:0, stagger:0.08, ease: Power4.out})
			menu.classList.remove('is-active')
			hamburger.classList.remove('is-active')
			noscroll()
			document.removeEventListener('click', closeMenuIfOutside)
			header.classList.add('is-active') // aggiunta questa forzatura per sicurezza
		}
	}
	
}




document.addEventListener('DOMContentLoaded', () => {
	document.querySelector('.js-toggle-main-menu').addEventListener('click', toggleMenu)
	
})