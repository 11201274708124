document.addEventListener('DOMContentLoaded', () => {
	let page = 1; // Initial page number
	const loadMoreBtn = document.getElementById('load-more-btn');
	const contentContainer = document.querySelector('.news-index');

	if (loadMoreBtn && contentContainer){
		loadMoreBtn.addEventListener('click', async () => {
			page++;
			try {
			  const response = await fetch(`/load-more-news?page=${page}`);
			  const data = await response.text();
			  // Check if the new content is already present in the container
		      if (!contentContainer.innerHTML.includes(data.trim())) {
		        contentContainer.insertAdjacentHTML('beforeend', data);
		      } else {
		        // If the content is already present, handle this scenario (optional)
		        console.log('Content already loaded');
		      }
			} catch (error) {
			  console.error('Error fetching more content:', error);
			}
		});
	}
	
})
