import { gsap, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const jobItems = {
	init: () => {
		let openJobItems = document.querySelectorAll('.js-open-job-item')
		openJobItems.forEach(i => {
			i.addEventListener('click', (e) => {
				let id = e.currentTarget.getAttribute('data-job')
				// openJobItems.forEach(ii => ii.classList.remove('is-active'))
				e.currentTarget.classList.toggle('is-active')
				// desktop classes
				// document.querySelectorAll('.js-job-item-body').forEach(body => body.classList.remove('is-active'))
				// if (!document.querySelector(`.js-job-item-body[data-job="${id}"]`).classList.include('is-active')){
					document.querySelector(`.js-job-item-body[data-job="${id}"]`).classList.toggle('is-active')
				// }
				
				// mobile classes
				// document.querySelectorAll('.job-items_left_body').forEach(body => body.classList.remove('is-active'))
				e.currentTarget.nextElementSibling.classList.toggle('is-active')
				if(window.innerWidth > 768){
					gsap.to(window, {duration: 1.5, scrollTo: 0, ease: "power4"})
				} else {
					gsap.to(window, {duration: 1.5, scrollTo: e.currentTarget, ease: "power4"})
				}
				
			})
		})
	}
}

document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.job-items')){
		jobItems.init()
	}
})