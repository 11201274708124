import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Back }  from "gsap";
const myPlugins = [CSSPlugin]

// import Sticky from 'sticky-js'

const story = {
	initSidebar: () => {
		let divs = document.querySelectorAll('.js-story-chapter')
		let links = document.querySelectorAll('.js-story-link')
		window.addEventListener('scroll', () => {
			divs.forEach(div => {
				let id = div.getAttribute('id')
				let elData = story.elementInViewport(div)
				if(elData.topHalf == true && elData.bottomHalf == false){
					document.querySelector(`.js-story-link[data-id="${id}"]`).classList.add('is-active')
				} else {
					document.querySelector(`.js-story-link[data-id="${id}"]`).classList.remove('is-active')
				}

			})
		})
	},
	initDots: () => {
		let dots = document.querySelectorAll('.js-story-dot')
		window.addEventListener('scroll', () => {
			dots.forEach(dot => {
				let elData = story.elementInViewport(dot)
				if(elData.topHalf == true){
					dot.classList.add('is-active')
					
				} else {
					dot.classList.remove('is-active')
				}

			})
		})
	},

	elementInViewport: (el) => {
		let vh = (window.innerHeight || document.documentElement.clientHeight) // alcuni browser 
		let elRect = el.getBoundingClientRect()
		let data = {
			topHalf: false, 
			bottomHalf: false
		}
		if(elRect.top < vh / 2){
			data.topHalf = true
		} 
		if ((elRect.height + elRect.y) < vh / 2){
			data.bottomHalf = true
		}
		return data
	},

	stickyLogos: () => {
		var fixedLogos = document.querySelector('.story_fixed-logos')
		var secondStoryItemY = document.querySelectorAll('.story_item')[1].offsetTop
		
		window.addEventListener('scroll', () => {
			let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
			var intersectionY = document.querySelector('.intersection').getBoundingClientRect().top + scrollPos
			if(secondStoryItemY < scrollPos && scrollPos < intersectionY){
				fixedLogos.classList.add('is-active')
			}else {
				fixedLogos.classList.remove('is-active')
			}
		})

		window.addEventListener('resize', () => {
			secondStoryItemY = document.querySelectorAll('.story_item')[1].offsetTop
			intersectionY = document.querySelector('.intersection').offsetTop
		})
		// var sticky = new Sticky('.logo-story');
		// window.addEventListener('scroll', () => {
		// 	let logos = [document.querySelector('.logo-elah'), document.querySelector('.logo-dufour'), document.querySelector('.logo-novi')]
		// 	let width = document.querySelector('.story_item_lines').getBoundingClientRect().width * 60/100
		// 	logos.forEach((item, ind) => {
		// 		let top = item.getBoundingClientRect().top
		// 		console.log(top)
		// 		if(top <= ind * 30 + 70){
		// 			item.style.position = 'fixed'
		// 			item.style.left = '16.666vw'
		// 			item.style.top = (ind * 30 + 70) + 'px'
		// 			item.style.width = width +'px'
		// 		} else if (top > ind * 30 + 70) {
		// 			console.log('qui')
		// 			item.removeAttribute('style')
		// 		}
		// 	})

		// })
	}
}







document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.story')){
		story.initSidebar()
		story.initDots()
		story.stickyLogos()
	}
})