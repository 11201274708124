import {mapStyles} from './map_styles'

const loadGoogleMapsApi = require('load-google-maps-api')

/// global constants ///
const center = { lat: 44.7376700, lng: 8.3372600}; 
const apiKey = 'AIzaSyAwE73FpReh480iE4eZrnVBaG55kAc8Zag'
/// API DI BOB, non eliminare da google ///


class Map {
    static loadGoogleMapsApi(){
        return loadGoogleMapsApi({key: apiKey})
    }
    static createMap(googleMaps, mapElement) {
        let map = new googleMaps.Map(mapElement, {
          center: center,
          zoom: 8,
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
          streetViewControl: true
        });
        return map
    }

    static geocodeAndAddMarkers(markerInfo_json, googleMaps, mapObject){

        let allMarkers = []
        let mapElement = document.querySelector('#map')

        // GEOCODE // use geocoder from Google API and not from rails 
        // (it is very unprecise and provides the same position for multiple points)
        let geocoder = new googleMaps.Geocoder()

        for(let i = 0; i < markerInfo_json.length; i++){
             
            geocoder.geocode({
                'address': markerInfo_json[i].address
                }, (results, status) => {
            
                    // let icon = {
                    //     scaledSize: new google.maps.Size(35, 50), // scaled size
                    //     origin: new google.maps.Point(0,0), // origin
                    //     anchor: new google.maps.Point(0,0), // anchor
                    // }
                    let marker = new googleMaps.Marker({
                        position: results[0].geometry.location, 
                        map: mapObject
                    })
                
                }
            )
        }  // end of markers creation cycle //
       


    }
}
//////////////////////
//// LOAD THE MAP ////
//////////////////////


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('group-map') != null){
        let mapElement = document.getElementById('group-map');
        let markerInfo_json = [{address: 'Strada Serravalle 73 Novi Ligure, Al 15067'}, {address: 'Via Don Orione 119/C, 12042 Bra (CN)'}]
        Map.loadGoogleMapsApi().then((googleMaps) => {
            let mapObject = Map.createMap(googleMaps, mapElement)
            Map.geocodeAndAddMarkers(markerInfo_json, googleMaps, mapObject)
        })
    }
    if (document.getElementById('contacts-map') != null){
        let mapElement = document.getElementById('contacts-map');
        let markerInfo_json = [{address: 'Strada Serravalle 73 Novi Ligure, Al 15067'}]
        Map.loadGoogleMapsApi().then((googleMaps) => {
            let mapObject = Map.createMap(googleMaps, mapElement)
            Map.geocodeAndAddMarkers(markerInfo_json, googleMaps, mapObject)
        })
    }
})




