import { gsap, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const scrollFunction = {
	init: () => {
		let scrollLinks = document.querySelectorAll('.js-scroll-to')
		scrollLinks.forEach(i => i.addEventListener('click', (e) => {
				let id = e.currentTarget.getAttribute('data-id')
				gsap.to(window, {duration: 1.5, scrollTo: `#${id}`, ease: "power4"})
			})
		)
	}
}



document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.js-scroll-to')){
		scrollFunction.init()
	}
})