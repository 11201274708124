const header = {
	init: () => {
		let headerDiv = document.querySelector('.header')
		let lastScrollTop
		document.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
		   let st = window.pageYOffset || document.documentElement.scrollTop; 
		   if (st > lastScrollTop && st > 100){
		      headerDiv.classList.remove('is-active')
		   } else {
		      headerDiv.classList.add('is-active')
		   }
		   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
		})
	}
}
 


document.addEventListener('DOMContentLoaded', () => {
	header.init()
})