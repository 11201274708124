import lozad from 'lozad'
import imagesLoaded from 'imagesloaded'

const lazyLoad = () => {
	let lazyloadItems = document.querySelectorAll('.lazyload')
	
	lazyloadItems.forEach(i => {
		let bg = i.getAttribute('data-bg')
		let src = i.getAttribute('data-src')
		if(bg != ''){
			i.style.background = `url('${bg}') no-repeat center center`
			i.style.backgroundSize = 'cover'
		}
		if(src != ''){
			i.src = src
		}
		i.classList.add('lazy-done')
	})
}





document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.lazyload')){
		const observer = lozad('.lazyload', {
	    	loaded: function(el) {
	        	el.classList.add('lazy-done');
	    	}
    	})
	observer.observe()
		// imagesLoaded('html', () => {lazyLoad()})
	}
})