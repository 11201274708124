import noscroll from'./noscroll'

const closeModal = {
	init: () => {
		if(document.querySelector('.js-close-modal')){
			let closeBtns = document.querySelectorAll('.js-close-modal')
			closeBtns.forEach(btn => btn.addEventListener('click', () => {
				document.querySelectorAll('.modal').forEach(mod => mod.classList.remove('is-active'))
				noscroll()
				let videos = document.querySelectorAll('video')
				if (videos){
					videos.forEach(vid => {
						vid.pause()
					})
				}
				

			}))
		}
	}
}




document.addEventListener('DOMContentLoaded', () => {
	closeModal.init()
})