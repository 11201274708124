import { gsap, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const accordion1 = {
	init: () => {
		let btns = document.querySelectorAll('.block-accordion-1_item')
		btns.forEach(btn => btn.addEventListener('click', (e) => {
			btns.forEach(i => i.classList.remove('is-active'))
			e.currentTarget.classList.add('is-active')
			let id = e.currentTarget.getAttribute('data-id')
			let bgImages = document.querySelectorAll('.block-accordion-1_image')
			bgImages.forEach(i => i.classList.remove('is-active'))
			document.querySelector(`.js-accordion-1-image-${id}`).classList.add('is-active')
		}))
	}
}

const accordion2 = {
	init: () => {
		let btns = document.querySelectorAll('.block-accordion-2_item')
		btns.forEach(btn => btn.addEventListener('click', (e) => {
			
			let id = e.currentTarget.getAttribute('data-id')
			let accordion_id = e.currentTarget.getAttribute('data-accordion')
			let accordion = document.querySelector(`#${accordion_id}`)
			let bgImages = accordion.querySelectorAll('.block-accordion-2_image')
			let accBtns = accordion.querySelectorAll('.block-accordion-2_item')
			accBtns.forEach(i => i.classList.remove('is-active'))
			e.currentTarget.classList.add('is-active')
			bgImages.forEach(i => i.classList.remove('is-active'))
			accordion.querySelector(`.js-accordion-2-image-${id}`).classList.add('is-active')
			if(window.innerWidth < 575){
				// forziamo header a rimanere nascosto se si autoscrolla verso l'alto
				document.querySelector('.header').style.display = 'none'
				gsap.to(window, {duration: 1, scrollTo: e.currentTarget, ease: "power4", 
									onComplete: () => {
										document.querySelector('.header').classList.remove('is-active')
										document.querySelector('.header').setAttribute('style', '')
									}})

			}
		}))
	}
}



document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.block-accordion-1')){
		accordion1.init()
	}
	if (document.querySelector('.block-accordion-2')){
		accordion2.init()
	}
})