import Plyr from 'plyr';
import noscroll from'./noscroll.js'
import Player from '@vimeo/player'
// questo codice è pensato per far funzionare più video in una pagina
const blockVideo = {
	initPlayer: (video) => {
		// const player = new Plyr(video)
	},
	initBtns: () => {
		let videoBtns = document.querySelectorAll('.js-open-video-modal')
		videoBtns.forEach(btn => btn.addEventListener('click', (e) => {
			let videoId = e.currentTarget.getAttribute('data-id')
			let videoModal = document.querySelector(`.js-video-modal-${videoId}`)
			let body = document.querySelector('body, html')
			videoModal.classList.add('is-active')
			noscroll()
			videoModal.querySelector('video').play()

		}))
	},
	play: () => {

	}
}

const storyVideo = {
	initPlayer: () => {
		
		
		
		let videoBtns = document.querySelectorAll('.js-open-story-video-modal')	
		let videoModal = document.querySelector(`.block-video_modal`)
		let playerDiv = document.querySelector('#story-video-player');
		
		videoBtns.forEach(btn => btn.addEventListener('click', (e) => {

			let videoId = e.currentTarget.getAttribute('data-id')
			var options = {id: videoId, responsive: true, controls: true, color: '#CBA55C', loop: false}
			var player = new Player(playerDiv, options);
			player.loadVideo(videoId)
			// player.source = {
			//   type: 'video',
			//   sources: [
			//     {
			//       src: videoId,
			//       provider: 'vimeo',
			//     },
			//   ],
			// };
			let closeBtns = document.querySelectorAll('.js-close-modal')
			closeBtns.forEach(btn => btn.addEventListener('click', () => {
				// player.pause()
				player.unload().then(function() {
				   
				}).catch(function(error) {
				});
			}))
			videoModal.classList.add('is-active')
			noscroll()

		}))

	}
}


document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.js-open-video-modal')){
		let blockVideos = document.querySelectorAll(".js-open-video-modal")
		blockVideos.forEach(i => blockVideo.initPlayer(i))
		blockVideo.initBtns()
	}

	if(document.querySelector('#story-video-player')){
		storyVideo.initPlayer()
	}
})